<template>
  <div v-if="integration_ice" class="grid md:grid-cols-2 gap-6">
    <div class="max-w-7xl mx-auto rounded-lg shadow-sw-1 overflow-hidden w-full">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex items-center">
        <img src="/images/ice-skating.svg" alt="" class="mx-2" />
        <h3 class="font-sans-bold m-0 flex-1">
          {{ $t("icepool.visitors_ice")}}
        </h3>
        <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider" :class="integration_ice.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100'">{{ integration_ice.status == 'open' ? 'open' : 'closed' }}</span>
      </div>
      <ul role="list" class="divide-y divide-gray-200">
        <li>
          <div class="px-4 py-4 sm:px-6 font-sans flex items-center">
            <span class="flex-1 pl-4" >{{ $t("general.lastUpdate")}} {{currentDate}} {{ $t("general.h")}}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'Ice',
  props: {
    page: Object,
  },
  data() {
    return {
      integrations: null,
      integration_ice: true,
      currentDate: new Intl.DateTimeFormat("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }).format(new Date())
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+'typo3temp/feed_dk/integrations.json').then(response => {
      this.integrations = response.data
      this.integration_ice = response.data.find(item => item.type_integration === "worldIceDavos");
    })
  },
}
</script>
